.product-listing {
    height: 400px;
    width: 360px;
    font-size: 14px;
    box-shadow: 0px 0px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    margin: 5px;
    background-color: white;
    -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.5s; /* Firefox < 16 */
     -ms-animation: fadein 0.5s; /* Internet Explorer */
      -o-animation: fadein 0.5s; /* Opera < 12.1 */
        animation: fadein 0.5s;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .flex-bottom {
        padding: 5px;
    }
        &__img {
        width: 100%;
        max-width: 100%;
        text-align: center;
        max-height: 70%;

        img {
            max-width: 100%;
            height: auto;
            max-height: 100%;
        };
    }

    &__design {
        font-weight: 600;
        line-height: 1.5;
        font-size: 10px;
    }

    &__title {
        font-weight: 500;
        line-height: 1.4;
        font-size: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__price {
        line-height: 1.6;
        font-weight: 700;
    }

    .box-padding {
        padding: 0 13px;
    }
}

@media only screen and (max-width: 1200px) {
    .product-listing {
        height: 200px;
        width: 170px;
        font-size: 12px;

        &__title {
            font-size: 12px;
        }

        .box-padding {
            padding: 0 5px;
        }
    
    }
}