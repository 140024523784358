.about {
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title {
        font-size: 64px;
        font-weight: normal;
        line-height: 1.3;
        text-align: center;
        margin-bottom: 10px;
    }

    p {
        max-width: 710px;
        margin: auto;
        font-size: 18px;
        line-height: 1.3;
        margin-top: 28px;
    }

    &__button {
        margin-top: 64px;
        height: 64px;
        background-color: #2C527C;
        border-radius: 4px;
        width: 710px;
        text-align: center;
        box-shadow: (0px 0px 10px rgba(0, 0, 0, 0.25));
        color: #ffffff;
        font-size: 38px;
        line-height: 1.5;
        display: flex;
        justify-content: center;
        align-items: center;

    }
}

@media only screen and (max-width: 1200px) {
    .about {
        width: 90%;
        margin: auto;

        &__title {
            font-size: 40px;
        }

        &__button {
            width: 80%;
            margin-top: 10px;
            font-size: 28px;
            height: 40px;
        }
    }
}