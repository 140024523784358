.design-slider-editor-form {
    padding: 30px;

    .form-input {
        padding: 10px;
        border: 1px solid black;

        .material-icons {
            text-align: right;
        }
    }

    .header-img {
        background-size: cover;
        background-position: 50%;
        position: relative;
        height: 250px;
        max-width: 100%;
        width: auto;
        margin-top: 20px;
    }

    .add-img {
        margin-top: 10px;
        width: 100%;
    }

    .image-inputs {
        border: 2px solid black;
        margin: 25px 0;
        padding: 0 15px 20px;

        .image-inputs-header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
    }

    label {
        font-size: 1.2rem;
        font-weight: 600;
    }
}
