.full-page-menu {
    position: fixed;
    top: 60px;
    left: 0;
    min-height: calc(100vh - 60px);
    width: 100vw;
    background-color: rgb(33, 72, 117);
    display: flex;
    flex-direction: column;

    .menu-item {
        padding: 20px 15px;
        border-bottom: 2px solid #fafafa;
        color: #fafafa;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .expanded-menu {
        background-color: #fafafa;
        overflow: hidden;
        transition: all 0.25s ease-in-out;

        .sub-menu-item {
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #ebeaea;
            margin: 0 10px;
        }
    }
}