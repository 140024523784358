.product-boxes {
    display: flex;
    flex-direction: column;
    align-items: center;

    .boxes-row {
        display: flex;
        margin-bottom: 20px;
    }

}

@media only screen and (max-width: 1010px) {
    .product-boxes {
        flex-direction: row;
        overflow: scroll;

        .boxes-row {
            margin-left: 15px;
        }

    }
}

.product-boxes.small {
    .boxes-row {
       max-width: 1257px;
    }

}

.product-boxes-spinner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    min-height: calc(100vh - 400px);
}