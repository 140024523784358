.main-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    min-height: calc(100vh - 244px);

    .gallery-item {
        max-width: 550px;
        margin: 2px;
        -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
        -moz-animation: fadein 0.5s; /* Firefox < 16 */
         -ms-animation: fadein 0.5s; /* Internet Explorer */
          -o-animation: fadein 0.5s; /* Opera < 12.1 */
            animation: fadein 0.5s;
        position: relative;
        cursor: pointer;

        img {
            width: 100%;
            height: auto;
            z-index: -1;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgb(162, 162, 162);
            opacity: 0;
            z-index: 1;
            transition: opacity .25s ease-in-out;
            -moz-transition: opacity .25s ease-in-out;
            -webkit-transition: opacity .25s ease-in-out;
            text-align: right;
        }

        .overlay:hover {
            opacity: 0.15;
        }
    }
}
