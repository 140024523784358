.box-editor-form {
    padding: 30px;

    .form-input {
        padding: 10px;
        border: 1px solid black;
    }

    .add-img {
        margin-top: 10px;
    }

    .image-inputs {
        border: 2px solid black;
        margin: 25px 0;
        padding: 0 15px 20px;

        .image-inputs-header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }
    }

    img {
        max-height: 160px;
        margin-top: 20px;
    }

    label {
        font-size: 1.2rem;
        font-weight: 600;
    }
}
