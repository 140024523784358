.order-page {
    padding: 129px 50px 50px;

    .back {
        display: flex;
        margin-bottom: 20px;
        align-items: center;
    }

    .back:hover {
        color: #0101bf;
    }

    .heading {
        padding-bottom: 20px;
        font-size: 25px;
    }
    .customer-name {
        font-size: 20px;
        font-weight: 500;
    }

    .customer-details .address {
        font-size: 20px;
        line-height: 1.3;

        h4 {
            margin: 0 0 10px;
        }

        address {
            font-style: normal;
        }
    }

    .customer-message {
        font-size: 20px;
        line-height: 1.3;
        margin-top: 30px;

        h4 {
            margin: 0 0 10px;
        }

        p {
            margin-top: 5px;
        }
    }
}