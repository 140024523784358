.admin-customize {
    padding: 104px 50px 50px 50px;

    .buttons {
        display: flex;
        flex-direction: column;
        width: 300px;
        
        .divider {
            margin-bottom: 20px;
        }

        button {
            margin-bottom: 10px;
        }
    }
    .page-title {
        font-weight: 800;
        font-size: 2rem;
    }

    .form-image {
        margin: 20px 0;
        max-width: 650px;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        .form-input {
            margin-top: 0;

            input {
                width: 500px;
            }
        }

        img {
            max-height: 100px;
            margin: 0 10px;
        }
    }
    
}