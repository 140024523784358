.product-dashboard {
    padding-left: 16px;
    padding-right: 16px;
    margin: 0 40px;
    
    &__title {
        font-size: 64px;
        text-align: center;
        font-weight: normal;
    }

    &__category-title {
        font-size: 48px;
        line-height: 1.3;
        padding-bottom: 16px;
        margin-top: 50px;
    }

    &__products-carousel {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;

        &.no-margin {
            margin: 0 -56px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .product-dashboard {
        padding-left: 0;
        padding-right: 0;
        margin: 0;

        &__title {
            font-size: 40px;
            text-align: center;
            font-weight: normal;
        }
    
        &__category-title {
            font-size: 30px;
            padding-bottom: 16px;
            padding-left: 10px;
            margin-top: 10px;
        }
    
        &__products-carousel {
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
    
            &.no-margin {
                margin: inherit;
            }
        }
    }
}