.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 100px;

    .home-title {
        margin-top: 100px;
        text-align: center;
        letter-spacing: 1px;
        font-size: 64px;
        line-height: 1.2;
        font-weight: 500;
    }
}

@media only screen and (max-width: 1200px) {
    .container {
        padding-bottom: 50px;

        .home-title {
            font-size: 30px;
            margin-top: 30px;
        }
    }
}