.empty-message {
    padding: 300px 0;
    text-align: center;

    .empty-message-link:hover {
        text-decoration: underline;
        color: blue;

    }
}

.design {
    padding: inherit 0 150px 0;
    position: relative;
    overflow-x: hidden;
    box-sizing: border-box;

    &__breadcrumbs {
        font-weight: 500;
        font-size: 14px;
        line-height: 1.4;
        padding-top: 16px;

        .active-link {
            font-weight: 700;
        }
    }

    .heading-text {
        font-size: 30px;
        line-height: 1.3;
        font-weight: 500;
        margin-top: 13px;
    }
    &__hero {
        height: auto;
        width: 100vw;
        position: absolute;
        z-index: -1;
        max-height: 300px;
        height: auto;

    }

    &__hero-div {
        width: 100%;
        max-height: 300px;
        height: 300px;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        position: relative;
    }


    &__header {
        height: 220px;
        width: 100%;
        position: relative;
        width: 100%;
        background-size: cover;
        background-position: center;
        position: relative;
        color: white;

        .link:hover {
            text-decoration: underline;
        }

        .active-link {
            font-weight: 700;
        }

        .heading-text {
            font-size: 48px;
            line-height: 1.3;
            margin-top: 32px;
        }
    }

    &__content {
        display: flex;
        margin-top: 20px;

        &__nav-box {
            min-width: 213px;
            height: 100%;
            background-color: white;
            border-radius: 4px;
            font-size: 18px;

            ul {
                padding: 10px;
                margin: 0;
                font-size: 14px;
                line-height: 1.8;

                li {
                    cursor: pointer;
                }

                li:hover {
                    text-decoration: underline;
                }
            }

            .selected {
                font-weight: bold;
            }
        }

        &__filter {
            display: none;
            align-items: center;
            padding: 5px 8px;
        }

        &__filter-items {
            transition: all 0.25s ease-in-out;
            overflow: hidden;

            ul {
                padding: 10px;
                margin: 0;
                font-size: 14px;
                line-height: 1.8;

                li {
                    cursor: pointer;
                }

                li:hover {
                    text-decoration: underline;
                }
            }

            .selected {
                font-weight: bold;
            }
        }

        &__listings {
            display: flex;
            flex-wrap: wrap;

            &__listing {
                margin-left: 10px;
                margin-bottom: 10px;
            }
        }

    }
}

@media only screen and (max-width: 1200px) {

    .design {
        padding: inherit 0 150px 0;
        position: relative;
        overflow-x: hidden;
        box-sizing: border-box;
    
        &__breadcrumbs {
            font-weight: 500;
            font-size: 14px;
            line-height: 1.4;
            padding-top: 16px;
    
            .active-link {
                font-weight: 700;
            }
        }
    
        .heading-text {
            font-size: 30px;
            line-height: 1.3;
            font-weight: 500;
            margin-top: 13px;
        }
        &__hero {
            height: auto;
            width: 100vw;
            position: absolute;
            z-index: -1;
            max-height: 300px;
            height: auto;
    
        }
    
        &__hero-div {
            width: 100%;
            max-height: 300px;
            height: 300px;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            position: relative;
        }
    
    
        &__header {
            height: 100px;
        }
    
        &__content {
            display: flex;
            margin-top: 20px;
    
            &__nav-box {
                display: none;
            }

            &__filter {
                display: flex;
            }
    
            &__listings {
                &__listing {
                    margin-left: 0;
                    margin-bottom: 10px;
                }
            }
    
        }
    }
}
