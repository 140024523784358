.checkout {
    padding: 100px 16px;
    max-width: 1257px;
    margin: auto;
    box-sizing: border-box;
    
    &__title {
        font-size: 64px;
        text-align: center;
        font-weight: normal;
    }

    .table {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto;
        background-color: #fff;
        color: #444;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }

    .mobile-items {
        display: none;

        .mobile-item {
            display: flex;
            padding: 30px 0;
            border-bottom: 1px solid #c5c5c5;

            .item-img {
                height: auto;
                max-width: 55%;
                min-width: 55%;

                img {
                    width: 100%;
                }
            }

            .item-description {
                div {
                    line-height: 1.3;
                    padding-bottom: 8px;
                }

                .item-price {
                    font-size: 18px;
                    font-weight: 500;
                }

                .item-quantity input {
                    width: 35px;
                }

                .remove {
                    border: none;
                    color: #ffffff;
                    background-color: #2c527c;
                    cursor: pointer;
                    margin-top: 8px;
                    font-size: 14px;
                    padding: 10px;
                    border-radius: 4px;
                }
            }
        }
    }
    
    .box {
        background-color: #fff;
        color: rgb(12, 12, 12);
        border-bottom: 1px solid #c9c9c9;
        padding: 10px;
        font-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &.product-name {
            display: flex;
            flex-direction: column;
        }
        &.cart-product-img {
            max-height: 60px;
            width: auto;

            img {
                max-height: 60px;
                width: auto;
            }
        }

        &.qty {
            input {
                max-width: 60px;

            }
        }
    }

    .table-actions {
        display: flex;
        justify-content: flex-end;
        i {
            padding: 0 10px;
            cursor: pointer;
        }
    }

    .title {
        font-size: 80%;
    }

    .checkout-btn {
       text-align: center;
       margin-top: 50px;

        .link {
            padding: 0 60px;
        }
    }

    .total {
        text-align: right;
        font-size: 1.5rem;
        margin-top: 20px;
    }

    .empty-cart {
        text-align: center;
        font-size: 1.5rem;
        margin-top: 100px;
    }

}

@media only screen and (max-width: 1200px) {
    .checkout {
        max-width: 100%;

        &__title {
            font-size: 40px;
        }

        .table {
            display: none;
        }

        .mobile-items {
            display: block;
        }
    }
}
