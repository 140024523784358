.products {
    padding: 104px 50px 50px 50px;

    .page-title {
        font-weight: 800;
        font-size: 2rem;
    }

    .header {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .create-new {
            max-height: 25px;
        }
    }

    .table {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
        background-color: #fff;
        color: #444;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    }
    
    .box {
        background-color: #fff;
        color: rgb(12, 12, 12);
        border-bottom: 1px solid #c9c9c9;
        padding: 10px;
        font-size: 100%;

        .view-listing {
            color: rgb(1, 1, 191);;
            font-size: 12px;
            display: flex;
            align-items: center;
        }

        .view-listing:hover {
            text-decoration: underline;
        }
    }

    .table-actions {
        display: flex;
        justify-content: flex-end;
        i {
            margin: 0 10px;
            cursor: pointer;
    
            &.delete {
                color: rgb(182, 0, 0);
            }
            &.edit {
                color: rgb(1, 1, 191);
                margin: 0 25px 0 10px;
            }
        }
    }

    .title {
        font-size: 80%;
    }
}