.admin-nav {
    font-size: 25px;
    padding: 25px 0;

    a {
        padding: 0 10px;
    }

    .selected {
        text-decoration: underline;
    }
}