.header-container {
    width: 100%;
    min-height: 104px;
    background-color: rgb(33, 72, 117);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2;

    .material-icons {
        color: white;
    }

    .desktop-header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        z-index: 2;
    }

    .item {
        margin: 0 16px;
        font-weight: 300;
        line-height: 1.6;

        &.selected {
            font-weight: 500;
            border-bottom: 2px solid white;
            border-radius: 2px;
        }

        &.cart {
            position: relative;
            display: flex;

            .cart-number {
                position: absolute;
                top: -6px;
                right: 3px;
                background-color: red;
                border-radius: 8px;
                height: 12px;
                min-width: 12px;
                font-size: 10px;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 2px;
            }
        }
    }

    .mobile-header {
        display: none;
        width: 100%;
        justify-content: space-around;
        align-items: center;

        .cart .cart-number {
            color: white;
            left: 10px;
        }
    }

    .admin-icon {
        position: relative;
        display: flex;

        .icon-number {
            position: absolute;
            top: -6px;
            right: 3px;
            background-color: red;
            border-radius: 8px;
            height: 12px;
            min-width: 12px;
            font-size: 10px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 2px;
        }
    }

    .nav-items {
        display: flex;
        color: white;
        width: 100%;
        justify-content: center;
        font-size: 18px;
    }

    .logo {
        max-width: 250px;
        position: absolute;
        top: 52;
        left: 80px;
    }

    .mobile-header {

        .logo {
            position: relative;
            left: 0;
            max-width: 160px;
        }

        .item {
            margin: 0;
        }

    } 


    .side-icons {
        color: white;
        position: absolute;
        top: 52;
        right: 70px;
        display: flex;
        align-items: center;

        .material-icons {
            margin-right: 10px
        }

        .dashboard {
            font-size: 14px;
            font-weight: 600;
            margin-left: 10px;

            &.selected {
                text-decoration: underline;
            }
        }

        .currency {
            height: 20px;
            width: 60px;
            font-size: 1rem;
            margin-right: 15px;
            padding: 0;
        }
    }
}


@media only screen and (max-width: 1200px) {
    .header-container {
        min-height: 60px;

        .mobile-header {
            display: flex;
        }

        .desktop-header {
            display: none;
        }
    }
}