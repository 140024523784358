.product-box {
    display: flex;
    // width: 400px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    margin-right: 20px;
    -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.5s; /* Firefox < 16 */
     -ms-animation: fadein 0.5s; /* Internet Explorer */
      -o-animation: fadein 0.5s; /* Opera < 12.1 */
         animation: fadein 0.5s;

    .side {
        min-width: 50%;
        background-size: cover;
        background-position: center;
        color: black;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      
        h2 {
            font-size: 36px;
            margin: 0;
            line-height: 2;
            letter-spacing: 0.01rem;
            font-weight: 500;
        }
        
        .side__btn {   
            background-color: transparent;
            padding: 10px;
            border: 2px solid black;
            font-weight: 500;
            font-size: 10px;
            color: black;
            cursor: pointer;
        }
    }

    &.row {
        height: 241px;
        width: 528px;
    }

    &.col {
        height: 391px;
        width: 345px;
        flex-direction: column;

        .short {
            height: 60%;
        }
    }
}

.small {
    .product-box .side {
        h2 {
            font-size: 25px;
        }

        .side__btn {
            font-size: 10px;
            padding: 10px;
        }
    }

    .col {
        height: 271px;
        width: 239px;
    }
}

@media only screen and (max-width: 1200px) {
    .product-box {
        margin-bottom: 20px;

        &.row, &.col {
            height: 350px;
            width: 300px;
            flex-direction: column;
        }

        .side h2 {
            font-size: 30px;
        }

    }
    
        
}


