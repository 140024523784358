.auth-container {
    width: 500px;
    margin: auto;

    .alternative {
        display: flex;
        align-items: center;
        margin-top: 30px;
        justify-content: flex-end;
        text-align: right;

        .link {
            font-weight: 700;
            color: #2B517B;
        }

        .link:hover {
            text-decoration: underline;

        }
    }

}