.slide {
    width: 100%;
    min-height: calc(100vh - 104px);
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    .rec-slider-container {
        margin: 0;
    }

    .gradient {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-image: linear-gradient(transparent, transparent, rgba(0, 0, 0.5, 0.5));
    }

    .arrow {
        color: white;
        position: absolute;
        top: 50%;
        margin-top: -30px;
        font-size: 60px;
        height: 60px;
        width: 60px;

        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }

    }

    .content {
        color: white;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 30px;
        z-index: 1;

        h2 {
            font-size: 64px;
            font-weight: 600;
            margin: 0;
            line-height: 1.5;
        }

        &__btn {   
            background-color: transparent;
            border: 2px solid white;
            color: white;
            font-weight: 700;
            cursor: pointer;
            padding: 10px;
        }

    }

}


.slider {
    .cNRLLS {
        margin: 0;
    }
}

@media only screen and (max-width: 1200px) {
    .slide {
        min-height: 400px;

        .arrow {
            font-size: 30px;
            height: 30px;
            width: 30px;
        }

        .content h2 {
            font-size: 35px;
        }
    }
}