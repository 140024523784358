.filters {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    .dropdowns {
        display: flex;
        width: 100%;
        justify-content: space-evenly;

        .form-input {
            width: 100%;
            margin-right: 10px;
        }
    }

    button {
        margin: 20px 0;
    }
}