.product-gallery {
    height: 100vh;
    width: 100vw;
    background-color: black;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    overflow: auto;

    img {
        max-height: 100vh;
        max-width: 100vw;
        height: auto;
        width: auto;
        border-radius: 4px;
        transition: transform .2s ease;

        &.zoom-1 {
            transform: scale(1.3);
        }
        &.zoom-2 {
            transform: scale(1.6);
        }
        &.zoom-3 {
            transform: scale(1.9);
        }
        &.zoom-4 {
            transform: scale(2.2);
        }
        &.zoom-5 {
            transform: scale(2.5);
        }
        &.zoom-6 {
            transform: scale(2.8);
        }
    }

    .close-btn {
        background: none;
        border: none;
        color: #c0c0c0;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 2;

        .material-icons {
            font-size: 35px;
        }
    }

    .zoom-btns {
        position: absolute;
        top: 5px;
        display: flex;
        z-index: 2;
        
        button {
            background: none;
            box-shadow: none;
            border: none;
            color: #c0c0c0;
            margin: 4px;

            .material-icons {
                font-size: 35px;
            }
        }
    }
}