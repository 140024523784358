.workshop-slider-container {
    margin-top: 50px;

    .workshop-slider {
        max-height: 300px;
        max-width: 1080px;
        display: flex;
        overflow: auto;

        .workshop-slide {
            width: 260px;
            height: 260px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: black;
            margin: 0 5px; 

            img {
                max-width: 260px;
                max-height: 260px;
                width: auto;
                height: auto;
            }
        }

       .cNRLLS {
            margin: 0;
        }   
    }
}

@media only screen and (max-width: 1200px) {
    .workshop-slider-container {
        margin: 50px 5px 0 5px;

    } .workshop-slider {
        max-width: 100%;
    }
}
