.product-text {
    min-height: 100%;
    max-width: 386px;

    .main.price {
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0.05rem;
    }

    .row {
        display: flex;
        width: 100%;

        input {
            max-width: 55px;
            max-height: 100%;
            font-size: 0.9rem;
            box-sizing: border-box;
    
        }
        .add-to-cart-btn {
            background-color: #2C527C;
            font-size: 20px;
            line-height: 1.2;
            flex-grow: 1;
            padding: 5px 0;
        }
    
    }
    
    .details {
        &__description {
            line-height: 1.4;
        }

        h4 {
            margin: 30px 0 15px 0;
        }

        ul {
            list-style: initial;
            padding-left: 20px;

            li {
                font-size: 14px;
                margin: 5px 0;

                span {
                    font-weight: 600;
                }
                &.bold {
                    font-weight: 600;
                }
            }
        }
    }

    .sub {
        font-size: 24px;
        line-height: 1.2l
    }

    .main {
        font-size: 36px;
        line-height: 1.4;
    }

    .divider {
        height: 2px;
        width: 100%;
        background-color: #2C527C;
        margin: 16px 0 32px 0;
    }

}

