.product-details-container {
    .breadcrumbs {
        font-weight: 500;
        font-size: 14px;
        line-height: 1.4;
        padding-top: 16px;

        .link:hover {
            text-decoration: underline;
        }

        .active-link {
            font-weight: 700;
        }
    }

    .related-products {
        margin: 60px 0;
    }
}
.mini-images {
    display: flex;
    max-height: 550px;
    overflow-x: auto;
    margin: 0 40px;
    -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.5s; /* Firefox < 16 */
     -ms-animation: fadein 0.5s; /* Internet Explorer */
      -o-animation: fadein 0.5s; /* Opera < 12.1 */
        animation: fadein 0.5s;

    &__img {
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0; 
        cursor: pointer;
        border-radius: 4px;
        border: 2px solid transparent;
        &.selected {
            border-color:#FBAE00;
        }

        img {
            max-width: 150px;
            max-height: 150px;
            width: auto;
            height: auto;
        }
    }
}

.product-content {
    display: flex;
    height: 620px;
    margin: 10px 40px;
    align-items: center;
    justify-content: center;

    .main-image {
        text-align: center;
        cursor: pointer;
        height: 100%;
        width: 60%;
        position: relative;
        display: flex;
        justify-content: center;

        button {
            position: absolute;
            background: none;
            box-shadow: none;
            border: none;
            color: black;
            margin: 4px;
            bottom: 0;
            top: 0;
    
            .material-icons {
                font-size: 40px;
            }

            &.prev-btn {
                left: 0;
            }

            &.next-btn {
                right:  0;
            }
        }

        img {
            border-radius: 4px;
            max-width: 100%;
            height: auto;
            max-height: 100%;
            align-self: center;
        }
    }

    .product-info {
        padding-left: 50px;
    }
}

::-webkit-scrollbar {
    // width: 0px;  /* Remove scrollbar space */
    // background: transparent;  /* Optional: just make scrollbar invisible */
}

@media only screen and (max-width: 1200px) {
    .product-content {
        flex-direction: column;
        height: auto;
        margin-left: 0;
        margin-right: 0;

        .main-image {
            width: 100%;
        }

        .product-info {
            padding-left: 10px;
            padding-right: 10px;

            .product-text {
                .main {
                    font-size: 28px;
                }
                .sub, .price {
                    font-size: 20px;
                }

                .row {
                    width: 90%;
                    margin: auto;
                }
        
            }
        }
    }

    .mini-images {
        margin: 0;
        padding: 0 10px;
    }
}