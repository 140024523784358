.workshop-container {
    max-width: 1077px;
    margin: auto;
    margin-top: 100px;

    .heading-text {
        font-size: 64px;
        line-height: 1.1;
        font-weight: normal;
        text-align: center;
    }

    .workshop-description {
        color: black;
        font-size: 20px;
        line-height: 1.5;
    }
}

@media only screen and (max-width: 1010px) {
    .workshop-container {
        margin-top: 0;
        max-width: 100%;
        .heading-text {
            font-size: 40px;
        }

        .workshop-description {
            max-width: 90%;
            margin: auto;
            text-align: justify;
            font-size: 15px;
        }
    }
}