.contact {
    padding: 100px 0;
    max-width: 710px;
    margin: auto;

    .contact-top {
        display: flex;

        address div {
            line-height: 1.8;
            font-style: initial;
        }

        .download-button {
            margin-top: 10px;
            height: 40px;
            background-color: #2C527C;
            border-radius: 4px;
            text-align: center;
            box-shadow: none;
            color: #ffffff;
            line-height: 1.5;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            cursor: pointer;
        }
    }
    .whatsapp-link {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    &__title {
        font-size: 64px;
        font-weight: normal;
        line-height: 1.3;
        text-align: center;
        margin-bottom: 10px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        padding: 30px 0;

        .row {
            display: flex;
            width: 100%;
        }

        label {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            font-size: 1rem;
            width: 100%;

            input, textarea {
                margin-top: 5px;
            }

            textarea {
                height: 200px;
            }
        }

        &__submit.heading-text {
            font-size: 30px;
            color: #d9d9d9;
            border-radius: 4px;
            background-color: #7a8b9e;
            border: none;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            color: #ffffff;
            background-color: #2c527c;
            cursor: pointer;
            padding: 10px 20px;

            &-disabled {
                background-color: #7a8b9e;
                color: #d9d9d9;
            }
        }
    }
}

.success {
    text-align: center;
    padding-top: 20px;
    color: green;
    font-weight: 600
}

@media only screen and (max-width: 1200px) {
    .contact {
        max-width: 90%;

        &__title {
            font-size: 40px;
        }

        &__form {
            padding: 0;
            .row {
                flex-direction: column;
                width: 100%;
            }

            &__submit.heading-text {
                font-size: 25px;
            }
        }
        .contact-top {
            flex-direction: column;
        }
    }
}