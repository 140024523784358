.footer-container {
    background-color: #2B517B;
    width: 100%;
    height: 140px;
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .social-icons {
        .fab {
            height: 32px;
            width: 32px;
            font-size: 32px;
        }

        .first {
            margin-right: 25px;
        }
    }

    .heading-text {
        margin-top: 20px;
        font-size: 16px;
        line-height: 1.2;
        font-family: inherit;
        letter-spacing: 0.05rem;
    }

    .vela {
        font-size: 14px;
        line-height: 2;
        font-weight: 600;
    }
}

@media only screen and (max-width: 1200px) {
    .footer-container {
        height: 90px;

        .heading-text {
            font-size: 12px;
            letter-spacing: 0.02rem;
            margin-top: 10px;
        }

        .vela {
            font-size: 10px;
        }

        .social-icons {
            .fab {
                height: 30px;
                width: 30px;
                font-size: 30px;
            }
    
            .first {
                margin-right: 20px;
            }
        }
    
    }
}