.artist-bio-container {
    width: 100%;
    background-color: black;
    color: white;
    margin-top: 100px;
    padding-bottom: 80px;

    .heading-text {
        font-size: 64px;
        line-height: 1.1;
        font-weight: normal;
        text-align: center;
    }

    .bio {
        display: flex;
        max-width: 1500px;
        margin: auto;
        justify-content: space-evenly;

        figure {
            width: 40%;
            margin: 0;

            .bio__img {
                max-width: 100%;
                height: auto;
            }
        }

        .bio__blurb {
            width: 40%;
            color: #d3d3d3;
            font-size: 16px;
            line-height: 1.5;

            .client-button {
                line-height: 14px;
                font-size: 10px;
                font-weight: 500;
                width: 113px;
                height: 35px;
                border: 1px solid white;
            }

            p {
                margin: 0;
                text-align: justify;
                padding-bottom: 15px;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .artist-bio-container {
        margin-top: 0;

        .heading-text {
            font-size: 40px;
        }

        .bio {
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
            .bio__blurb {
                width: 100%;
    
                p {
                    max-width: 90%;
                    margin: auto;
                    text-align: justify;
                    font-size: 15px;
                }
                
                p:first-child {
                    padding-top: 15px;
                }
            }

            figure {
                width: 80%;
            }
        }

    }
    
        
}